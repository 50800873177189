<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-header text-center bg-primary">
        <h3>Create Brand AX</h3>
      </div>
      <div class="card-body">
        <h5>
          <router-link
            :to="{
              name: brandItemAx.name,
            }"
          >
            <span class="badge badge-primary"
              ><i class="fa fa-rotate-left mr-1"></i>Back</span
            >
          </router-link>
        </h5>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="organization"
                >Name<span class="text-danger">*</span> :</label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="type item name..."
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Brand<span class="text-danger">*</span> :
              </label>
              <multiselect
                v-model="brand"
                label="name"
                track-by="name"
                placeholder="select brand..."
                open-direction="bottom"
                required="required"
                :options="brandSearchOptions"
                :searchable="true"
                :loading="isBrandSearch"
                :close-on-select="true"
                :options-limit="10"
                :multiple="false"
                :allow-empty="false"
                @search-change="brandFind"
              >
              </multiselect>
            </div>

            <div class="form-group">
              <label for="organization"
                >Base Price<span class="text-danger">*</span> :</label
              >
              <input
                v-model="basePrice"
                type="number"
                class="form-control"
                placeholder="49000"
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Subscription ID AX<span class="text-danger">*</span> :</label
              >
              <input
                v-model="subsIdAx"
                type="number"
                class="form-control"
                placeholder="528196376"
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Vendor<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="vendor"
                label="name"
                track-by="name"
                placeholder="type to find vendor..."
                open-direction="bottom"
                :options="items_vendor"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                @search-change="findVendorName"
                @input="getVendorAx"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>

            <div class="form-group">
              <label>Vendor ID AX<span class="text-danger">*</span> : </label>
              <input
                v-model="vendor_id_ax"
                type="text"
                class="form-control"
                placeholder="V-000005276"
              />
            </div>

            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="addBrandAx()"
            >
              <i class="fa fa-plus"></i>
              Create Brand AX
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { brandItemAx } from '../../router/content';

export default {
  name: 'CreateBrandAX',
  data() {
    return {
      brandItemAx,
      name: null,
      brand: [],
      brandSearchOptions: [],
      isBrandSearch: false,
      basePrice: '',
      subsIdAx: '',
      vendor: [],
      items_vendor: [],
      isLoadingSearch: false,
      vendor_id_ax: '',
    };
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/ax/items/brand-ax');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Create Brand AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.brandAx.isLoading,
      isError: (state) => state.brandAx.isError,
      successMessage: (state) => state.brandAx.SuccessMessage,
      errorMessage: (state) => state.brandAx.ErrorMessage,
    }),
  },
  methods: {
    ...mapActions('brandAx', ['createBrandAx', 'createBrandAx']),
    ...mapActions('brands', ['searchBrands']),
    ...mapActions('vendors', ['findVendor', 'fetchVendorsById']),

    brandFind(query) {
      if (!query) return;
      this.isBrandSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchBrands(payload)
          .then((response) => {
            this.brandSearchOptions = response.data.data.rows;
            this.isBrandSearch = false;
          })
          .catch(() => {
            this.isBrandSearch = false;
          });
      }, 600);
    },

    findVendorName(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          q: keyword,
        };
        this.findVendor(payload)
          .then((resp) => {
            this.items_vendor = resp.data.data;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    getVendorAx() {
      this.fetchVendorsById(this.vendor).then((res) => {
        this.vendor_id_ax = res.data?.data?.sync_vendor?.vendor_id_ax;
      });
    },

    addBrandAx() {
      const payload = {
        name: this.name,
        brandId: parseInt(this.brand?.id, 10),
        basePrice: parseInt(this.basePrice, 10),
        subs_Idax: this.subsIdAx,
        vendorId: this.vendor ? parseInt(this.vendor.id) : null,
        vendorName: this.vendor ? this.vendor.name : null,
        vendor_Idax: this.vendor_id_ax,
      };
      this.createBrandAx(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
